
@import "modules/responsive-type.scss";

$primary: #c2185b;/* MAIN COLOR */
$secondary: #f48fb1; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footer-bg: #000;
$footerLinks: #fafafa;
$btn-color: #470914;

$c1: #ed1e79;
$c2: #ffffff;


// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Play|Russo+One');
// font-family: 'Play', sans-serif;
// font-family: 'Russo One', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

li, a, p {
    font-family: 'Play', sans-serif;
    font-size: 0.8rem;
}

h1 {
  font-family: 'Russo One', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
  font-family: 'Russo One', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
 font-family: 'Russo One', sans-serif;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

q,
blockquote {
    quotes: "“" "”";
}

.flash {
    display: none;
}

//NAVBAR//

.logo{
    margin-top: -15px;
    max-width: 250px;

    @media (max-width: 1199px){
         max-width: 220px;
    }
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border:none;
}

.navbar-brand img {
    width: 100%;
    display: block;
}

.navbar {
    background: #000;
    height: 100px;

    @media (max-width: 767px) {
        height: 98px;
        margin: 0px;
    }    
}

.navbar-right {
    // font-size: 25px;
    float: right!important;
    margin-right: -80px;
    margin-top: 24px;

    @media (max-width:1300px){
        margin-right: 0px;
    }

    @media (max-width:768px) {
        margin-top: 0px;
        background: rgba(0, 0, 0, 0.88);
        text-align: center;
        border-radius: 0px 0px 5px 5px;
        padding: 20px;
        width: 100%;
    }

    li a {
        color: $c1 !important;

        &:hover {
            color: $c2 !important;
        }
    } 
}

.navbar-toggle {
    margin-top: 29px !important;
}
.navbar-toggle .collapsed{
    margin-top: -20px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

.navbar-fixed-top {
    border: $c1 !important;
        border-width: 0 0 1px;
}

.navbar-default {
    border: #F29117 !important;
    border-width: 0 0 1px;
}

.navbar-toggle {
    margin-bottom: 34px;
}

.navbar-default .navbar-toggle {
    border-color: $c1;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: $c1;
}

body {
    background: #000;
    color: #fff;
    font-family: 'Play', sans-serif;
}

.panel1 {
    background-image:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url('/img/banner.jpg');
    background-attachment: fixed;
    width: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0px;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }

    img {
        float: center;
        padding: 150px;

        @media (max-width: 767px) {
            padding: 100px;
        }

        @media (max-width: 450px) {
            padding: 50px;
        }        
    }
}


h3 {
    color:$c1;
}

#wlogo {
    padding: 50px;
}


.panel-top {
    img {
        size: cover;
    }
}

//ABOUT SECTION //

.abrow {
    padding: 50px;
    float: center;

    @media (max-width: 767px) {
        padding: 20px;
    }    
}

.abcon {
    background-image:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url('/img/banner3.jpg');
    width: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    margin-bottom: 0px;
    padding: 500px 0px 50px;

    @media (max-width: 1366px) {
        background-attachment: scroll;
         background-position: 50% 50%;
    }

    @media (max-width: 768px) {
        background-attachment: scroll;
         background-position: 32% 50%;
    }
}

.abtext {
    background: rgba(0, 0, 0, 0.7);
    padding: 25px;
    float: left;
    border-radius: 15px;

    h3 {
        color: white;
        text-align: center;
    }

    p {
        color:$wht;
    }
}

//Services//

.servcon {
    padding-bottom: 25px;

    h3 {
        font-size: 23px;
    }
}
//Panel 2 //

.ctest{
    padding: 25px 0px;
    background: rgba(0,0,0, .7);
    border-radius: 25px;
    color: white;

       h2{
        margin-top: 0px;
    }
    carousel-content p {
        color: white;
    }
    margin-left: 10px;
    margin-right: 10px;
    h2 {
        color: $c1;
        text-shadow: 1px 1px 0px #000;
    }
}

.werow {
    padding-left: 25px;
    padding-right: 25px;
    text-shadow: 1px 1px 1px #000;

    h3,p {
        color:white;
    }
}

.panel2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)),url('/img/banner2.jpg');
    padding: 25px;
    box-shadow: 0px 0px 25px #000 inset;
    background-attachment: fixed;
    width: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }

    img {
        padding-bottom: 15px;
    }
}

.carousel-content {
    color:black;
    display:flex;
    align-items:center;
    
}


//MODAL CONTENT//

.modal-content {
  // background-color: $c1;
  background-color: $wht;
  padding: 18px;
  text-align: center;
  color: $c1;
    // font-family: 'Lato';

    h2 {
    font-size: 23px;
    }
    a {
        color: $c1 !important;
        text-decoration: none;
    }
}


.aboutImg {

    @media (max-width: 1200px) {
        margin-top: 15em;
    }

    @media (max-width: 991px) {
        margin-top: 0em;
    }    
}


@media (max-width: 561px) {
  .modal-content h2 {
    // font-size: 18px;
  }
}
@media (max-width: 417px) {
  .modal-content h2 {
    // font-size: 17px;
  }
}
@media (max-width: 290px) {
  .modal-content h2 {
    // font-size: 14px;
  }
}
.modal-content a {
  color: #F7F8F2;
}

.padding {
    padding-top: 25px;
}

.iconthis{
    font-size: 3em;
    color: $wht;
}

.iconit{
    font-size: 3em;
    color: $c1;
}

footer 
   a {
        color: $c1 !important;
        text-decoration: none;

        &:hover {
            color: $c2 !important;
            text-decoration: none;
        }
}